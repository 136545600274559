import React, { useEffect } from 'react'
import { FormSpy } from 'react-final-form'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import type { Views } from './constants'

const LiveBlockEditorOrchestrator = () => {
  const { selectedBlockState, updateBlock, dashboardEditorState, editableState } = useDashboard()
  const { params: { block } = {} } = useRecoilValue<DashboardEditorView<Views.EDIT_BLOCK>>(
    dashboardEditorState
  )
  const selectedBlock = useRecoilValue(selectedBlockState)! || block
  const { urn } = useDashboardEditorContextProvider()

  const setEditable = useSetRecoilState(editableState)

  useEffect(() => {
    if (selectedBlock) setEditable(true)
  }, [ selectedBlock, setEditable ])

  return (
    <FormSpy
      onChange={({ values: { identifier, actions, ...properties }, valid }) => {
        valid && updateBlock(urn, {
          ...selectedBlock,
          actions,
          identifier,
          properties: {
            ...selectedBlock.properties,
            ...properties
          }
        })
      }}
    />
  )
}

export default LiveBlockEditorOrchestrator
