import React from 'react'
import isObject from 'lodash/isObject'
import get from 'lodash/get'
import { test } from 'uuid-random'

import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { RendererOptions } from 'components/dataTable/types'

const StyledText = styled(Text, {
  color: 'currentColor'
})

const renderData = (data: any): any => {
  if (Array.isArray(data)) {
    return (
      data.map(renderData).join(', ')
    )
  }

  if (isObject(data)) {
    // @ts-ignore
    return Object.values(data).filter((id) => !test(id)).map(renderData).join(' • ')
  }

  return data
}

function TextRenderer<T extends object>({
  dataKey,
  rowData,
  prefix = '',
  suffix = ''
}: RendererOptions<T>) {
  const key = [ prefix, dataKey, suffix ].filter(Boolean).join('.')
  const data = get(rowData, key)

  return (
    <StyledText as="span" truncate>
      {renderData(data)}
    </StyledText>
  )
}

export default TextRenderer
