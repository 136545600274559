import dayjs from 'dayjs'
import React, { SetStateAction, useMemo, useState } from 'react'
import get from 'lodash/get'

import * as mixins from 'styles/mixins'
import AccountModel, { Kind } from 'models/Account'
import ActivityListItem from 'components/list/ActivityListItem'
import AddContactView from 'components/views/AddContactView'
import AssignRoleView from 'components/views/AssignRoleView'
import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import ContactValueRenderer from 'components/renderers/ContactValueRenderer'
import CopyToClipboard from 'components/buttons/CopyToClipboard'
import DataList, { RendererOptions } from 'components/dataList/DataList'
import DataTable from 'components/dataTable/DataTable'
import DateRenderer from 'components/renderers/DateRenderer'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import IconRenderer from 'components/renderers/IconRenderer'
import List from 'components/list/List'
import ListItem from 'components/list/ListItem'
import SensitiveText from 'components/typography/SensitiveText'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import Toolbar from 'components/toolbar/Toolbar'
import useConfirmation from 'hooks/useConfirmation'
import usePager from 'hooks/usePager'
import useSubmitHandler from 'hooks/useSubmitHandler'
import useSearch from 'hooks/useSearch'
import AssignGroupView from './AssignGroupView'
import ChipRenderer from 'components/renderers/ChipRenderer'
import {
  ContactsAggregateDocument,
  ContactsListDocument,
  ContactsListQuery,
  CustomRole,
  GroupMembership,
  GroupMembershipsListDocument,
  GroupMembershipsListQuery,
  GroupMembershipsListQueryVariables,
  KeyPair,
  KeyPairsListDocument,
  RoleMembership,
  RoleMembershipsListDocument,
  UpdateAccountInput,
  useAccountQuery,
  useContactsAggregateQuery,
  useContactsListQuery,
  useCreateKeyPairMutation,
  useDestroyContactMutation,
  useDestroyGroupMembershipMutation,
  useDestroyRoleMembershipMutation,
  useEventsListQuery,
  useKeyPairsListQuery,
  useRevokeKeyPairMutation,
  useRoleMembershipsListQuery,
  useUpdateAccountMutation
} from 'generated/schema'
import { css, styled } from 'styles/stitches'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { DIALOG_PADDING } from 'components/dialog/constants'
import { generateStatusRenderer } from 'components/renderers/StatusRenderer'
import { Kind as RoleKind, KIND_ICON_MAP as ROLE_KIND_ICON_MAP } from 'models/Role'
import { KIND_ICON_MAP, STATUS_LABELS_MAP, STATUS_VARIANTS_MAP } from 'models/Contact'
import { PermissionText } from './RoleView'
import { SERVER_DATETIME_FORMAT } from 'components/inputs/DateInput/DateInput'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Account, ContactFragmentFragment as ContactFragment } from 'generated/schema'
import type { Column } from 'components/dataTable/types'
import type { Content } from 'components/dataList/DataList'
import type { RowAction } from 'components/dataWidgets/RowActions'
import type { ViewProps } from 'components/views'

const TITLE = 'Account Overview'
const GENERAL_RELATED_FIELDS = [ 'Activity', 'Contacts', 'Groups' ]
const SERVICE_ACCESS_RELATED_FIELDS = [ 'API Keys' ]

const StatusRenderer = generateStatusRenderer<ContactsListQuery['contactsList'][number]>({
  isCollapsible: false,
  statusMap: STATUS_LABELS_MAP,
  statusVariants: STATUS_VARIANTS_MAP
})

const RelatedListItem = styled(ListItem, {
  ...mixins.transition('simple'),

  [`${Icon}`]: {
    color: 'dark100'
  },

  '&:hover': {
    [`${Icon}`]: {
      color: 'dark300'
    }
  }
})

const HelpContent = styled(Flex, {
  variants: {
    color: {
      dark: {
        [`& ${Icon}`]: {
          color: 'dark500'
        }
      },
      positive: {
        [`& ${Icon}`]: {
          color: 'positive500'
        }
      },
      negative: {
        [`& ${Icon}`]: {
          color: 'negative500'
        }
      },
      warning: {
        [`& ${Icon}`]: {
          color: 'warning500'
        }
      }
    }
  }
})

const APIKeys = (
  { accountId, status = 'active' }: { accountId: Account['id'], status?: 'active' | 'revoked' }
) => {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const confirm = useConfirmation()
  const [ count, setCount ] = useState(0)

  const queryVariables = useMemo(() => ({
    filter: {
      accountId: { eq: accountId },

      ...(status === 'active' && { or: [
        { expiresAt: { gt: dayjs().utc().add(1, 's').format(SERVER_DATETIME_FORMAT) } },
        { expiresAt: 'null' }
      ] }),

      ...(status === 'revoked' && { and: [
        { expiresAt: { lte: dayjs().utc().add(1, 's').format(SERVER_DATETIME_FORMAT) } },
        { expiresAt: 'notNull' }
      ] })
    },
    order: [
      ...(status === 'active' ? [ { createdAt: 'asc' } ] : []),
      ...(status === 'revoked' ? [ { expiresAt: 'desc' } ] : [])
    ],
    limit: pageSize,
    page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [ accountId, count, page, pageSize, status ])

  const {
    data: { keyPairsList = [] } = {},
    loading,
    error
  } = useKeyPairsListQuery({
    variables: queryVariables
  })

  const [ createKeyPair ] = useCreateKeyPairMutation({
    refetchQueries: [ KeyPairsListDocument ]
  })

  const [ revokeKeyPair ] = useRevokeKeyPairMutation({
    onCompleted: () => { setCount((c) => c + 1) }
  })

  const handleCreateKeyPair = useSubmitHandler(createKeyPair)

  const handleRevokeKeyPair = useSubmitHandler(revokeKeyPair, {
    update: {
      strategy: 'REMOVE',
      query: KeyPairsListDocument,
      queryVariables,
      dataKey: 'keyPairsList',
      mutation: 'revokeKeyPair'
    }
  })

  const onRevokeClick = (keyPair: KeyPair) => {
    confirm({
      action: 'revoke',
      recordType: 'API Key',
      recordDescription: 'This action is not reversible',
      onConfirmClick: () => handleRevokeKeyPair({ id: keyPair.id })
    })
  }

  const columns: Column[] = [
    {
      dataKey: 'publicKey',
      title: 'Public Key',
      sortable: false,
      style: { width: 300 },
      renderer: (({ rowData, dataKey }: RendererOptions) => (
        <Flex alignItems="center" grow={1} style={{ width: 0 }}>
          <Text truncate fontSize={14} fontWeight="bold">{rowData[dataKey]}</Text>
          <CopyToClipboard textToCopy={rowData[dataKey]} />
        </Flex>
      ))
    },
    {
      dataKey: 'privateKey',
      title: 'Private Key',
      sortable: false,
      style: { width: 350 },
      renderer: (({ rowData, dataKey }: RendererOptions) => (
        <Flex alignItems="center" grow={1} style={{ width: 0 }}>
          <SensitiveText truncate fontSize={14} fontWeight="bold" variant="spaced">{rowData[dataKey]}</SensitiveText>
          <CopyToClipboard textToCopy={rowData[dataKey]} />
        </Flex>
      ))
    }
  ]

  columns.push(status === 'active'
    ? { dataKey: 'createdAt', title: 'Created', style: { justifyContent: 'flex-end' }, sortable: false, renderer: DateRenderer }
    : { dataKey: 'expiresAt', title: 'Revoked', style: { justifyContent: 'flex-end' }, sortable: false, renderer: DateRenderer })

  const actions: RowAction[] = [
    {
      icon: 'trash',
      title: 'Revoke',
      onClick: onRevokeClick,
      visibilityFilter: (keyPair: KeyPair) => !keyPair.expiresAt
    }
  ]

  return (
    <Flex direction="column" gap={24}>
      <DataTable
        actions={actions}
        columns={columns as any}
        data={keyPairsList}
        error={error}
        loading={loading}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        selectionMode="none"
      />
      {status === 'active' && (
        <Flex alignItems="center">
          <Button
            label="Generate API Key"
            onClick={() => handleCreateKeyPair({ accountId })}
          />
        </Flex>
      )}
    </Flex>
  )
}

const ContactsList = (
  { accountId, targetEnvironment }: { accountId: Account['id'], targetEnvironment?: string }
) => {
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const queryVariables = {
    filter: {
      accountId: { eq: accountId }
    },
    page,
    limit: pageSize
  }

  const {
    data: { contactsList = [] } = {},
    loading,
    error
  } = useContactsListQuery({
    variables: queryVariables
  })

  const {
    data: { contactsAggregate = {} } = {}
  } = useContactsAggregateQuery({
    variables: {
      filter: queryVariables.filter
    }
  })

  const [ deleteContact ] = useDestroyContactMutation({
    refetchQueries: [
      { query: ContactsListDocument, variables: queryVariables },
      {
        query: ContactsAggregateDocument,
        variables: {
          filter: queryVariables.filter, targetEnvironment
        }
      }
    ]
  })

  const handleDelete = useSubmitHandler(deleteContact)

  const openAddContactView = () => openView({
    title: 'New Contact',
    component: AddContactView,
    params: {
      queryVariables,
      initialValues: {
        accountId,
        targetEnvironment
      }
    },
    style: 'PANEL'
  })

  const openEditContactView = (contact: ContactFragment) => openView({
    title: 'Edit Contact',
    component: AddContactView,
    params: {
      queryVariables,
      initialValues: {
        ...contact,
        targetEnvironment
      }
    },
    style: 'PANEL'
  })

  const openDeleteContactDialog = (contact: ContactFragment) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => handleDelete({ id: contact.id, targetEnvironment }),
      recordType: 'Contact',
      recordDescription: contact.name
    })
  }

  const secondaryElement = <Button icon="add-thin" size="small" onClick={openAddContactView} />

  const contents: Content<ContactFragment>[] = [
    { dataKey: 'kind', slot: 'icon', renderer: (props) => <IconRenderer iconMap={KIND_ICON_MAP} {...props} /> },
    { dataKey: 'name', slot: 'primary', renderer: ({ rowData, dataKey }) => <Text>{get(rowData, dataKey) || '--'}</Text> },
    { dataKey: 'deviceModel', slot: 'secondary', renderer: ContactValueRenderer },
    { dataKey: 'status', slot: 'meta', renderer: StatusRenderer }
  ]

  const actions = [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: openEditContactView
    },
    {
      icon: 'trash',
      title: 'Delete',
      onClick: openDeleteContactDialog
    }
  ]

  return (
    <>
      <Toolbar
        secondaryElements={secondaryElement}
      />
      <DataList
        actions={actions}
        contents={contents}
        data={contactsList as ContactFragment[]}
        loading={loading}
        error={error}
        dataListItemVariant="detailed"
        selectionMode="none"
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        totalRows={contactsAggregate.count || 0}
      />
    </>
  )
}

const Groups = (
  { account, targetEnvironment }: { account: Account, targetEnvironment?: string }
) => {
  const { openView } = useViewDispatch()
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const queryVariables = {
    filter: {
      accountId: { eq: account.id }
    },
    order: [ { createdAt: 'desc' } ],
    limit: 100
  }

  const [ {
    data: { groupMembershipsList = [], groupMembershipsAggregate = {} } = {},
    error,
    loading
  } ] = useSearch<GroupMembershipsListQuery, GroupMembershipsListQueryVariables>({
    query: GroupMembershipsListDocument,
    queryOptions: {
      variables: queryVariables
    },
    keys: [ ]
  })

  const openAssignGroup = () => {
    openView({
      title: 'Assign Group',
      component: AssignGroupView,
      params: {
        accountId: account.id,
        account,
        accountKind: account.kind,
        environmentId: targetEnvironment,
        queryVariables
      },
      style: 'DIALOG'
    })
  }

  const contents: Content[] = [
    { dataKey: 'group.name', slot: 'primary' },
    { dataKey: 'group.accountKind', slot: 'meta', renderer: ChipRenderer }
  ]

  const [ deleteGroupMembership ] = useDestroyGroupMembershipMutation({
    refetchQueries: [
      { query: GroupMembershipsListDocument, variables: queryVariables }
    ]
  })

  const handleDeleteGroup = useSubmitHandler(deleteGroupMembership, {
    update: {
      strategy: 'REMOVE',
      query: GroupMembershipsListDocument,
      queryVariables,
      dataKey: 'groupMembershipsList',
      mutation: 'destroyGroupMembership'
    }
  })

  const openDeleteGroupMembershipDialog = (groupMembership: GroupMembership) => {
    confirm({
      action: 'remove',
      onConfirmClick: async () => handleDeleteGroup({ id: groupMembership.id }),
      recordType: 'Member',
      recordDescription: AccountModel.getFullName(groupMembership.account)
    })
  }

  const actions = [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (groupMembership: GroupMembership) => (
        openDeleteGroupMembershipDialog(groupMembership)
      )
    }
  ]

  const secondaryElement = <Button icon="add-thin" size="small" onClick={openAssignGroup} />

  return (
    <Flex direction="column" gap={16}>
      <Toolbar
        // primaryElements={<SearchBar placeholder="Search..." onChange={handleChange} />}
        secondaryElements={secondaryElement}
      />
      <DataList
        actions={actions}
        contents={contents}
        data={groupMembershipsList as GroupMembership[]}
        loading={loading}
        error={error}
        selectionMode="none"
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"
        totalRows={groupMembershipsAggregate.count || 0}
      />
    </Flex>
  )
}

const ActivityList = (
  { accountId }: { accountId: Account['id'] }
) => {
  const queryVariables = {
    filter: {
      accountId: { eq: accountId }
    },
    order: [ { createdAt: 'desc' } ],
    limit: 100
  }

  const {
    data: { eventsList = [] } = {},
    loading
  } = useEventsListQuery({
    variables: queryVariables
  })

  // const secondaryElement = <Button icon="filter" size="small" />

  return (
    <>
      {/* <Toolbar
        secondaryElements={secondaryElement}
      /> */}
      <List gap={10} loading={loading}>
        {eventsList.map(({ eventType: { name }, createdAt: timestamp, data }, index) => (
          <ActivityListItem
            activity={{ name, timestamp, data }}
            isLast={index === eventsList.length - 1}
          />
        ))}
      </List>
    </>
  )
}

const RolePolicy = ({ role }: { role: CustomRole }) => {
  const operationsPolicies = role.policy.operations
  const resourcesPolicies = role.policy.resources

  const operations = Object.keys(operationsPolicies || {})
  const resources = Object.keys(resourcesPolicies || {})

  return (
    <>
      {resources.map((resource) => (
        <PermissionText policyKey={resource} policy={resourcesPolicies} parentName="resource" />
      ))}
      {operations.map((operation) => (
        <PermissionText policyKey={operation} policy={operationsPolicies} parentName="operation" />
      ))}
    </>

  )
}

const Permission = (
  { account }: { account: Account }
) => {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const queryVariables = {
    filter: {
      accountId: { eq: account.id }
    },
    order: [ { createdAt: 'desc' } ],
    limit: 100
  }

  const {
    data: { roleMembershipsList = [], roleMembershipsAggregate = {} } = {},
    loading,
    error
  } = useRoleMembershipsListQuery({
    variables: queryVariables
  })

  const [ updateAccount ] = useUpdateAccountMutation()
  const handleLoginAccessToggle = useSubmitHandler(updateAccount, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateAccount',
      typename: 'Account',
      override: (values: UpdateAccountInput) => ({
        ...account,
        ...values
      })
    }
  })

  const apiAccessButtonLabel = account.hasApiAccess ? 'Revoke API Access' : 'Grant API Access'
  const apiAccessHelpText = account.hasApiAccess ? 'API is enabled' : 'No API access'

  const customRoleMemberships = roleMembershipsList.filter((rm) => rm.role.workspaceId !== null)

  const openAssignRole = () => {
    openView({
      title: 'Assign Role',
      component: AssignRoleView,
      params: {
        accountId: account.id,
        roleMemberships: roleMembershipsList,
        account,
        mode: 'role',
        kind: RoleKind.WORKSPACE,
        queryVariables
      },
      style: 'DIALOG'
    })
  }

  const contents: Content[] = [
    { dataKey: 'role.name', slot: 'primary' },
    { dataKey: 'role.kind', slot: 'icon', renderer: (props) => <IconRenderer iconMap={ROLE_KIND_ICON_MAP} {...props} /> },
    { dataKey: 'environment',
      slot: 'toggle',
      renderer: ({ rowData, dataKey }) => {
        const isWorkspaceRole = rowData.role.kind === 'WORKSPACE'
        if (isWorkspaceRole) return <></>
        const environment = get(rowData, dataKey)
        const hasEnvironment = Boolean(environment)
        return <Chip label={hasEnvironment ? environment.name : 'All Environments'} variant="accent_inverse" />
      } }
  ]

  const [ deleteRoleMembership ] = useDestroyRoleMembershipMutation({
    refetchQueries: [
      { query: RoleMembershipsListDocument, variables: queryVariables }
    ]
  })

  const handleDeleteRole = useSubmitHandler(deleteRoleMembership, {
    update: {
      strategy: 'REMOVE',
      query: RoleMembershipsListDocument,
      queryVariables,
      dataKey: 'roleMembershipsList',
      mutation: 'destroyRoleMembership'
    }
  })

  const openDeleteRoleMembershipDialog = (roleMembership: RoleMembership) => {
    confirm({
      action: 'delete',
      onConfirmClick: async () => handleDeleteRole({ id: roleMembership.id }),
      recordType: 'Role',
      recordDescription: roleMembership.role.name
    })
  }

  const actions = [
    {
      icon: 'trash',
      title: 'Delete',
      onClick: (roleMembership: RoleMembership) => openDeleteRoleMembershipDialog(roleMembership)
    }
  ]

  const secondaryElement = <Button icon="add-thin" size="small" onClick={openAssignRole} />

  return (
    <Flex direction="column" gap={16}>
      {account.kind === Kind.MEMBER && (
      <Flex direction="column" gap={12}>
        <Text fontWeight="bold">Login Access</Text>
        <Text fontSize={14} color="dark600">
          Allow this account to log into this Workspace.
          For Team members or external members that need login.
          Requires email address.
        </Text>

        <Flex gap={12}>
          <Button
            size="small"
            onClick={() => {
              handleLoginAccessToggle({
                id: account.id, hasGuiAccess: !account.hasGuiAccess
              })
            }}
            {...AccountModel.getLoginAccessButtonProps(account)}
          />
          <HelpContent alignItems="center" gap={8} color={AccountModel.getLoginAccessIconColor(account)}>
            <Icon name={AccountModel.getLoginAccessIcon(account)} />
            <Text fontSize={14}>
              {AccountModel.getLoginAccessText(account)}
            </Text>
          </HelpContent>
        </Flex>
      </Flex>
      )}

      {account.kind === Kind.SERVICE && (
      <Flex direction="column" gap={12}>
        <Text fontWeight="bold">API Access</Text>
        <Text fontSize={14} color="dark600">
          Allow this account programmatic access using API Keys.
          For Bots, Services, Applications, and some daring humans.
        </Text>
        <Flex gap={12}>
          <Button
            label={apiAccessButtonLabel}
            variant={account.hasApiAccess ? 'outline' : 'filled'}
            mode={account.hasApiAccess ? 'subtle' : 'distinct'}
            size="small"
            onClick={() => {
              handleLoginAccessToggle({
                id: account.id, hasApiAccess: !account.hasApiAccess
              })
            }}
          />
          <HelpContent alignItems="center" gap={8} color={account.hasApiAccess ? 'positive' : 'dark'}>
            <Icon name={account.hasApiAccess ? 'tick-circle' : 'decline'} />
            <Text fontSize={14}>{apiAccessHelpText}</Text>
          </HelpContent>
        </Flex>
      </Flex>
      )}

      <Toolbar title="Roles" secondaryElements={secondaryElement} />
      <DataList
        actions={actions}
        contents={contents}
        data={roleMembershipsList as RoleMembership[]}
        loading={loading}
        error={error}
        selectionMode="none"
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
        paginationMode="finite"

        totalRows={roleMembershipsAggregate.count || 0}
      />
      {customRoleMemberships.length > 0 && (
        <Flex direction="column" gap={12}>
          <Flex direction="column" gap={8}>
            <Text fontSize={16} fontWeight="bold">Permissions</Text>
            <Text fontSize={12} color="dark500">Custom policy directly added to this account</Text>
          </Flex>
          {customRoleMemberships.map((rm) => <RolePolicy role={rm.role} />)}
        </Flex>
      )}
    </Flex>
  )
}

const OverviewTab = ({ account, setRelated }: {
  account: Account,
  setRelated: React.Dispatch<SetStateAction<string | null>>
}) => (
  <>
    <Flex direction="column" gap={36}>
      <Flex direction="column" gap={12}>
        <Text fontWeight="bold">Details</Text>
        <Flex direction="column" gap={8}>
          <FieldLabel>Name</FieldLabel>
          <Text>{AccountModel.getFullName(account)}</Text>
        </Flex>
        <Flex direction="column" gap={8}>
          <FieldLabel>Email</FieldLabel>
          <Text>{account.email || '--'}</Text>
        </Flex>
        {![ Kind.SERVICE, Kind.MEMBER ].includes(account.kind as Kind) && (
          <>
            <Flex direction="column" gap={8}>
              <FieldLabel>UID</FieldLabel>
              <Text>{account.uid || '--'}</Text>
            </Flex>
            <Flex direction="column" gap={8}>
              <FieldLabel>Anonymous UID</FieldLabel>
              <Text>{account.anonymousUid || '--'}</Text>
            </Flex>
          </>
        )}
      </Flex>

      <Flex direction="column" gap={12}>
        <Text fontWeight="bold">Related</Text>
        <List gap={8}>
          {GENERAL_RELATED_FIELDS.concat(
            // eslint-disable-next-line no-nested-ternary
            [ Kind.MEMBER, Kind.SERVICE ].includes(account.kind as Kind)
              ? account.kind === Kind.SERVICE ? SERVICE_ACCESS_RELATED_FIELDS : []
              : []
          ).sort().map((related) => (
            <RelatedListItem key={related} height={48} alignItems="center" justifyContent="space-between" onClick={() => setRelated(related)}>
              <Text fontSize={14} fontWeight="semibold">{related}</Text>
              <Icon name="arrow-right" size={12} />
            </RelatedListItem>
          ))}
        </List>
      </Flex>
    </Flex>
  </>
)

const relatedViews = (account: Account, related: string, status?: 'active' | 'revoked') => {
  if (related === 'Activity') return <ActivityList accountId={account.id} />
  if (related === 'API Keys') return <APIKeys accountId={account.id} status={status} />
  if (related === 'Contacts') return <ContactsList accountId={account.id} targetEnvironment={account.environmentId} />
  if (related === 'Groups') return <Groups account={account} targetEnvironment={account.environmentId} />

  return <></>
}

const classes = {
  tabsList: css({ flexGrow: 1, paddingX: DIALOG_PADDING })
}

function AccountView({
  onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<{ account: Account }>) {
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const [ status, setStatus ] = useState<'active' | 'revoked'>('active')
  const [ related, setRelated ] = useState<string | null>(null)
  const { data } = useAccountQuery({
    variables: {
      id: params.account.id
    },
    skip: !params.account.id
  })

  const account = data?.account || params.account

  const title = AccountModel.getFullName(account) || account.email || ''

  return (
    <View contentLabel={TITLE} onRequestClose={onRequestClose} {...other}>
      {({ Header, SubHeader, Body }) => (
        <>
          <Header
            title={related ? 'Back' : title}
            onCloseClick={onRequestClose}
            {...(related && { onBackArrowClick: () => setRelated(null) })}
          >
            {!related && <Chip label={account.kind} variant="accent_inverse" />}
          </Header>
          {related && (
            <SubHeader>
              <Flex direction="column" alignItems="stretch" grow={1} gap={4}>
                <Text color="dark900" fontSize={24} fontWeight="bold" letterSpacing="compact">
                  {related}
                </Text>
                <Text color="dark500" fontSize={12}>{AccountModel.getFullName(account)}</Text>
              </Flex>
              <Chip label={account.kind} variant="accent_inverse" />
            </SubHeader>
          )}
          <Tabs
            tabListClassName={classes.tabsList}
            tabListBackground="light"
            onChange={(index) => {
              if (related === 'API Keys' && index === 1) setStatus('revoked')
              if (related === 'API Keys' && index === 0) setStatus('active')
              setCurrentIndex(index)
            }}
          >
            {!related && (
              <>
                <Tab index={0} label="Overview" />
                <Tab index={1} label="Permissions" />
              </>
            )}

            {related === 'API Keys' && (
              <>
                <Tab index={0} label="Active" />
                <Tab index={1} label="Revoked" />
              </>
            )}
          </Tabs>
          <Body>
            {/* eslint-disable-next-line no-nested-ternary */}
            {related
              ? relatedViews(account as Account, related, status)
              : (currentIndex === 0
                ? (
                  <OverviewTab
                    account={account as Account}
                    setRelated={setRelated}
                  />
                )
                : <Permission account={account as Account} />
              )}
          </Body>
        </>
      )}
    </View>
  )
}

AccountView.defaultStyle = 'PANEL' as const

export default AccountView
