import { useApolloClient } from '@apollo/client'
import type { DocumentNode } from '@apollo/client'
import type { DropResult } from 'react-beautiful-dnd'

import generatePosition from 'lib/generatePosition'

type Input = {
  id: string,
  position: number
}

type Reorder = {
  broadcast?: boolean,
  query: DocumentNode,
  dataKey: string,
  callback: (input: Input) => void,
  variables: Record<string, any>
}

function reorderData<T extends OrderableData>(
  sourceIndex: number,
  targetIndex: number,
  data: T[]
) {
  const dataCopy = data.slice()

  const [ removed ] = dataCopy.splice(sourceIndex, 1)
  dataCopy.splice(targetIndex, 0, removed)

  dataCopy[targetIndex] = {
    ...dataCopy[targetIndex],

    position: generatePosition(
      dataCopy[targetIndex - 1]?.position,
      dataCopy[targetIndex + 1]?.position
    )
  }

  return dataCopy
}

type OrderableData = { id: string, position: number } & Record<any, any>

const rebalance = <T extends OrderableData, >(
  data: T[],
  targetIndex: number,
  callback: (variables: OrderableData) => void
) => {
  if (data[targetIndex].position === data[targetIndex - 1]?.position) {
    callback({
      id: data[targetIndex - 1].id,
      position: generatePosition(
        data[targetIndex - 2]?.position,
        data[targetIndex - 1].position
      )
    })
  }

  if (data[targetIndex]?.position === data[targetIndex + 1]?.position) {
    callback({
      id: data[targetIndex - 1].id,
      position: generatePosition(
        data[targetIndex + 1]?.position,
        data[targetIndex + 2]?.position
      )
    })
  }
}

const useReorder = ({ query, variables, dataKey, callback, broadcast }: Reorder) => {
  const client = useApolloClient()

  return (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) { return null }

    const sourceIndex = result.source.index
    const targetIndex = result.destination.index

    const cachedData = client.readQuery({
      query, variables
    }) || {}

    const sortedData = reorderData(sourceIndex, targetIndex, [ ...(cachedData[dataKey] || []) ])

    client.writeQuery({
      // TODO:- Investigate why this causes refetch
      broadcast,
      query,
      variables,
      data: {
        ...cachedData,
        [dataKey]: sortedData
      }
    })

    rebalance(sortedData, targetIndex, callback)

    const { id, position } = sortedData[targetIndex]
    return callback({ id, position })
  }
}

export {
  rebalance,
  reorderData
}

export type { OrderableData }

export default useReorder
