import { array, object, string } from 'yup'

import Attribute from './Attribute'
import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'
import type { DataTypeFragmentFragment } from 'generated/schema'
import type { FieldIdentifier } from './Field'

enum DataTypeKind {
  AUDIO = 'AUDIO',
  BIGINT = 'BIGINT',
  BIGDECIMAL = 'BIGDECIMAL',
  BINARY = 'BINARY',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DOCUMENT = 'DOCUMENT',
  PRESENTATION = 'PRESENTATION',
  SPREADSHEET = 'SPREADSHEET',
  DURATION = 'DURATION',
  ENUM = 'ENUM',
  FILE = 'FILE',
  FLOAT = 'FLOAT',
  ID = 'ID',
  IMAGE = 'IMAGE',
  INT = 'INT',
  JSON = 'JSON',
  OBJECT = 'OBJECT',
  UNION = 'UNION',
  STRING = 'STRING',
  TIME = 'TIME',
  TIMESTAMP = 'TIMESTAMP',
  UUID = 'UUID',
  VIDEO = 'VIDEO'
}

const fileDataTypes = [
  DataTypeKind.AUDIO,
  DataTypeKind.DOCUMENT,
  DataTypeKind.FILE,
  DataTypeKind.IMAGE,
  DataTypeKind.VIDEO
]

const nonPrimitiveDataTypes = [
  DataTypeKind.ENUM,
  DataTypeKind.OBJECT
]

const dataTypesWithSettings = [
  DataTypeKind.BIGDECIMAL,
  ...fileDataTypes
]

const DEFAULT_DATA_TYPES = [
  { kind: DataTypeKind.ENUM, name: 'Enum', category: 'ADVANCED' },
  { kind: DataTypeKind.OBJECT, name: 'Object', category: 'ADVANCED' },
  { kind: DataTypeKind.UNION, name: 'Union', category: 'ADVANCED' }
]

class DataType extends BaseModel {
  static schema = object({
    identifier: isIdentifier(),
    name: string().required(),
    settings: object({
      fields: array().of(object({})).required(),
      title_field_id: string()
    }).required()
  })

  static getTitleField = (fields: any[], dataType?: DataTypeFragmentFragment) => {
    const actualTitleField = dataType?.settings?.title_field_id
      ? fields.find((field) => field.id === dataType.settings.title_field_id)
      : undefined

    let potentialTitleField: any | undefined

    if (!actualTitleField) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (Attribute.isTitleableField(field.fieldType as FieldIdentifier)) {
          potentialTitleField = field
          break
        }
      }
    }

    return { actualTitleField, potentialTitleField }
  }

  static hasDataTypeSettings = (dataType?: DataTypeKind) => (
    dataType && dataTypesWithSettings.includes(dataType)
  )
}

export default DataType

export {
  DataTypeKind,
  DEFAULT_DATA_TYPES,
  fileDataTypes,
  nonPrimitiveDataTypes
}
