import React, { forwardRef } from 'react'
import type { PropsWithRef, Ref } from 'react'

import * as mixins from 'styles/mixins'
import BaseLink from 'components/links/BaseLink'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import rgba from 'lib/rgba'
import useSafeSetState from 'hooks/useSafeSetState'
import { colorVars, IconSize } from 'styles/theme'
import { styled } from 'styles/stitches'
import type { BaseLinkProps } from 'components/links/BaseLink'

type ButtonElement = typeof BaseLink | 'button'

type ButtonMode = 'distinct' | 'subtle' | 'negative'
type ButtonSize = 'small' | 'normal' | 'large'

type ButtonOwnProps = {
  icon?: string,
  iconSize?: IconSize,
  label?: string,
  onClick?: (e: React.MouseEvent) => void | Promise<any>
}

type ButtonProps =
  | BaseLinkProps & ButtonOwnProps & Omit<StyledProps<typeof StyledButton>, 'labelType'>
  | ButtonOwnProps & Omit<StyledProps<typeof StyledButton>, 'labelType'>

const BUTTON_BORDER_RADIUS = 4
const BUTTON_INTERNAL_SPACING = 10
const BUTTON_PADDING_X = 20

const BUTTON_MIN_WIDTH: { [key in ButtonSize]: number } = {
  small: 120,
  normal: 185,
  large: 190
}

const BUTTON_SIZE: { [key in ButtonSize]: number } = {
  small: 40,
  normal: 60,
  large: 80
}

const DEFAULT_ELEMENT: ButtonElement = 'button'

const sizes = {
  small: {
    height: BUTTON_SIZE.small
  },
  normal: {
    height: BUTTON_SIZE.normal
  },
  large: {
    height: BUTTON_SIZE.large
  }
}

const modes = {
  distinct: {},
  subtle: {},
  negative: {}
}

const variants = {
  filled: {},
  outline: {},
  simple: {}
}

const StyledButton = styled(Flex, {
  ...mixins.transition('fluid'),

  borderWidth: 0,
  color: 'light100',
  cursor: 'pointer',
  fontFamily: 'normal',
  fontSize: '12',
  fontWeight: 'bold',
  lineHeight: 'normal',

  '&&&[disabled], &&&[data-disabled]': {
    pointerEvents: 'none'
  },

  variants: {
    iconPlacement: {
      left: {},
      right: {}
    },
    labelType: {
      none: {},
      normal: {
        paddingX: BUTTON_PADDING_X
      }
    },
    rounded: {
      all: {
        borderRadius: BUTTON_BORDER_RADIUS
      },
      left: {
        borderTopLeftRadius: BUTTON_BORDER_RADIUS,
        borderBottomLeftRadius: BUTTON_BORDER_RADIUS
      },
      right: {
        borderTopRightRadius: BUTTON_BORDER_RADIUS,
        borderBottomRightRadius: BUTTON_BORDER_RADIUS
      },
      none: {
        borderRadius: 0
      }
    },
    size: sizes,
    mode: modes,
    variant: variants
  }
})

const getStyle = {
  filled: (mode: ButtonMode) => {
    let shadowColor
    let hoverBgColor
    let activeBgColor
    let disabledBgColor

    switch (mode) {
      case 'distinct':
        shadowColor = colorVars.accent600rgb
        hoverBgColor = colorVars.accent500
        activeBgColor = colorVars.accent400
        disabledBgColor = colorVars.accent200
        break

      case 'subtle':
        shadowColor = colorVars.dark700rgb
        hoverBgColor = colorVars.dark800
        activeBgColor = colorVars.dark700
        disabledBgColor = colorVars.dark100
        break

      case 'negative':
        shadowColor = colorVars.negative700rgb
        hoverBgColor = colorVars.negative700
        activeBgColor = colorVars.negative500
        disabledBgColor = colorVars.negative200
        break

      default:
        shadowColor = colorVars.dark700rgb
        hoverBgColor = colorVars.dark800
        activeBgColor = colorVars.dark700
        disabledBgColor = colorVars.dark100
        break
    }

    const hoverStyle = {
      ...mixins.shadow('large', shadowColor, 0.5),
      backgroundColor: hoverBgColor
    }

    const activeStyle = {
      ...mixins.shadow('large', shadowColor, 0.5),
      backgroundColor: activeBgColor
    }

    const disabledStyle = {
      backgroundColor: disabledBgColor,
      boxShadow: 'none'
    }

    return ({
      ...mixins.shadow('small', shadowColor, 0.3),
      backgroundColor: activeBgColor,

      '&:hover, &:focus': hoverStyle,
      '&:active': activeStyle,
      '&&&[disabled], &&&[data-disabled]': disabledStyle
    })
  },
  outline: (mode: ButtonMode) => {
    let primaryColor
    let secondaryColor
    let tertiaryColor

    switch (mode) {
      case 'distinct':
        primaryColor = colorVars.primary300
        secondaryColor = colorVars.primary200rgb
        tertiaryColor = colorVars.primary200
        break

      case 'subtle':
        primaryColor = colorVars.dark700
        secondaryColor = colorVars.dark500rgb
        tertiaryColor = colorVars.dark500
        break

      case 'negative':
        primaryColor = colorVars.negative300
        secondaryColor = colorVars.negative200rgb
        tertiaryColor = colorVars.negative200
        break

      default:
        primaryColor = colorVars.dark700
        secondaryColor = colorVars.dark500rgb
        tertiaryColor = colorVars.dark500
        break
    }

    const hoverStyle = {
      ...mixins.innerBorder(primaryColor, 2),
      color: primaryColor
    }

    const disabledStyle = {
      ...mixins.innerBorder(rgba(secondaryColor, 0.3), 2),
      color: rgba(secondaryColor, 0.3)
    }

    return ({
      ...mixins.innerBorder(tertiaryColor, 2),

      background: 'transparent',
      color: tertiaryColor,

      '&:hover, &:focus': hoverStyle,
      '&&&[disabled], &&&[data-disabled]': disabledStyle
    })
  },
  simple: (mode: ButtonMode) => {
    let primaryColor
    let secondaryColor
    let tertiaryColor

    switch (mode) {
      case 'distinct':
        primaryColor = colorVars.primary300
        secondaryColor = colorVars.primary300rgb
        tertiaryColor = colorVars.primary300
        break

      case 'subtle':
        primaryColor = colorVars.dark700
        secondaryColor = colorVars.dark500rgb
        tertiaryColor = colorVars.dark500
        break

      case 'negative':
        primaryColor = colorVars.negative300
        secondaryColor = colorVars.negative300rgb
        tertiaryColor = colorVars.negative300
        break

      default:
        primaryColor = colorVars.dark700
        secondaryColor = colorVars.dark500rgb
        tertiaryColor = colorVars.dark500
        break
    }

    const hoverStyle = {
      ...mixins.innerBorder(primaryColor, 2)
    }

    const disabledStyle = {
      ...mixins.innerBorder(rgba(secondaryColor, 0.3), 2),
      color: rgba(secondaryColor, 0.3)
    }

    return ({
      ...mixins.innerBorder(tertiaryColor),

      background: 'light100',
      color: primaryColor,

      '&:hover, &:focus': hoverStyle,
      '&&&[disabled], &&&[data-disabled]': disabledStyle
    })
  }
}

const buttonSizes = Object.keys(sizes) as ButtonSize[]
const buttonModes = Object.keys(modes) as Array<keyof typeof modes>
const buttonVariants = Object.keys(variants) as Array<keyof typeof variants>

buttonSizes.forEach((size) => {
  ([ 'none', 'normal' ] as const).forEach((labelType) => {
    StyledButton.compoundVariant(
      {
        size,
        labelType
      },
      labelType === 'none'
        ? { width: BUTTON_SIZE[size] }
        : { minWidth: BUTTON_MIN_WIDTH[size] }
    )
  })
})

buttonModes.forEach((mode) => {
  buttonVariants.forEach((variant) => {
    StyledButton.compoundVariant(
      {
        mode,
        variant
      },
      getStyle[variant](mode)
    )
  })
})

StyledButton.compoundVariant({
  iconPlacement: 'left',
  labelType: 'normal'
},
{
  '& > [data-icon]': {
    marginLeft: -BUTTON_INTERNAL_SPACING,
    marginRight: BUTTON_INTERNAL_SPACING
  }

})

StyledButton.compoundVariant({
  iconPlacement: 'right',
  labelType: 'normal'
},
{
  '& > [data-icon]': {
    marginLeft: BUTTON_INTERNAL_SPACING,
    marginRight: -BUTTON_INTERNAL_SPACING
  }
})

StyledButton.defaultProps = {
  iconPlacement: 'left',
  mode: 'distinct',
  rounded: 'all',
  size: 'normal',
  variant: 'filled'
}

const Button = forwardRef(({
  disabled,
  href,
  icon,
  iconSize,
  iconPlacement = 'left',
  label,
  onClick,
  to,
  type = 'button',

  ...others
}: ButtonProps,
ref: Ref<any>) => {
  const [ loading, setLoading ] = useSafeSetState(false)

  const Element = (href || to) ? BaseLink : DEFAULT_ELEMENT

  const elementProps = Element === DEFAULT_ELEMENT
    ? { disabled: disabled || loading, type }
    : { 'data-disabled': disabled || loading ? '' : null, href, to }

  const handleClick = (event: React.MouseEvent<any>): Promise<any> | void => {
    if (!onClick) {
      return undefined
    }

    const intermediate = onClick(event)

    // Check if the return value of onClick exists and is a promise intermediate
    if (!intermediate || !(intermediate instanceof Promise)) {
      return undefined
    }

    setLoading(true)

    return intermediate.finally(() => {
      setLoading(false)
    })
  }

  return (
    <StyledButton
      as={Element}
      alignItems="center"
      display="inline-flex"
      iconPlacement={iconPlacement}
      justifyContent="center"
      labelType={label ? 'normal' : 'none'}
      onClick={handleClick}
      ref={ref}
      shrink={0}
      {...elementProps}
      {...others}
    >
      {icon && iconPlacement === 'left' && <Icon data-icon size={iconSize} name={icon} />}
      {label && <span>{label}</span>}
      {icon && iconPlacement === 'right' && <Icon data-icon size={iconSize} name={icon} />}
    </StyledButton>
  )
})

Button.displayName = 'Button'

export default Button as (props: PropsWithRef<ButtonProps>) => JSX.Element

export type { ButtonProps }
