import React, { useRef } from 'react'
import Spinner from 'react-activity/dist/Spinner'
import type { ChangeEvent } from 'react'

import colors, { colorVars } from 'styles/primitives/colors'
import Icon from 'components/icons/Icon'
import Flex from 'components/layout/Flex'
import rgba from 'lib/rgba'
import { styled } from 'styles/stitches'
import type { FlexProps } from 'components/layout/Flex'

import 'react-activity/dist/Spinner.css'

const SEARCH_BAR_PADDING_X = 20
const SEARCH_BAR_PADDING_Y = 12

const StyledSearchBar = styled(Flex, {
  backgroundColor: 'light500',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderColor: 'light700',
  borderRadius: 4,
  minWidth: 150,
  width: '100%',
  overflow: 'hidden',
  paddingY: SEARCH_BAR_PADDING_Y,
  paddingX: SEARCH_BAR_PADDING_X,
  position: 'relative',
  zIndex: 'none',

  '&&& .rai-spinner-outer': { margin: 0 },

  '& [data-icon]': {
    color: rgba(colorVars.dark900rgb, 0.7),
    marginRight: 16
  },

  '&:hover [data-icon], &:focus-within [data-icon]': {
    color: 'dark900'
  },

  '& input:placeholder-shown + button[data-clear]': {
    display: 'none'
  },

  '& [data-clear]': {
    position: 'absolute',
    right: 0,
    marginRight: 24.5,
    cursor: 'pointer',
    zIndex: 'above'
  }
})

const StyledSearchInput = styled('input', {
  position: 'absolute',
  inset: 0,
  zIndex: 'below',
  paddingLeft: 48,
  backgroundColor: 'light500',
  border: 'none',
  color: 'dark900',
  fontFamily: 'normal',
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: 'normal',
  width: '100%',
  height: '100%',

  '&::placeholder': {
    color: rgba(colorVars.dark500rgb, 0.5),
    opacity: 1
  }
})

type SearchBarProps = {
  className?: string,
  loading?: boolean,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder: string
} & FlexProps

function SearchBar({
  autofocus, className, loading = false, placeholder, onChange, ...others
}: SearchBarProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <StyledSearchBar as="form" alignItems="center" className={className} {...others}>
      <Icon data-icon name="search" />
      <StyledSearchInput
        autoFocus={autofocus}
        placeholder={placeholder}
        onChange={onChange}
        ref={inputRef}
      />
      {/* eslint-disable-next-line react/button-has-type */}
      <button
        type="reset"
        aria-label="clear"
        data-clear
        // @ts-ignore
        onClick={onChange}
      >
        <Icon
          name="close"
          size={8}
        />
      </button>
      <Spinner
        style={{ flexShrink: 0, position: 'absolute', right: 16 }}
        color={colors.dark100}
        size={16}
        speed={1}
        animating={loading}
      />
    </StyledSearchBar>
  )
}

export default SearchBar
