import omit from 'lodash/omit'
import React, { memo } from 'react'
import type { DraggableProvided } from 'react-beautiful-dnd'

import get from 'lodash/get'

import * as mixins from 'styles/mixins'
import CheckboxInput from 'components/inputs/CheckboxInput'
import Flex from 'components/layout/Flex'
import DragHandle from 'components/dataTable/DragHandle'
import RowActions from 'components/dataWidgets/RowActions'
import TextRenderer from 'components/renderers/TextRenderer'
import useHover from 'hooks/useHover'
import { colorVars } from 'styles/theme'
import { DATA_TABLE_CELL_COMPACT_HEIGHT, DATA_TABLE_CELL_COZY_HEIGHT, DATA_TABLE_CELL_PADDING, DATA_TABLE_ROW_BORDER_RADIUS, DATA_TABLE_ROW_BORDER_WIDTH } from 'components/dataTable/constants'
import { styled } from 'styles/stitches'
import { useDataManagerContext } from 'hooks/useDataManagerContext'
import type { Column } from 'components/dataTable/types'

type TableRowProps = Partial<DraggableProvided> & {
  columns: Column[],
  index: number,
  /** existence of `start` means virtualization is true */
  start?: number,
  size?: StyledProps<typeof StyledTableRow>['size']
}

type RowCellsProps = {
  columns: Column[],
  datum: any,
  isHovered: boolean,
  size?: StyledProps<typeof StyledTableRow>['size']
}

const nestedProperties = [ 'index', 'childrenCount', 'isOpen' ]

const StyledTableRow = styled('div', {
  ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1),
  ...mixins.transition('simple', 'background'),

  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: DATA_TABLE_ROW_BORDER_RADIUS,
  display: 'flex',
  flexBasis: 0,
  flexDirection: 'row',
  width: 'fit-content',
  minWidth: '100%',

  '& + &': {
    borderTopColor: 'dark100',
    borderTopStyle: 'solid',
    borderTopWidth: DATA_TABLE_ROW_BORDER_WIDTH
  },

  variants: {
    size: {
      compact: {
        height: DATA_TABLE_CELL_COMPACT_HEIGHT
      },
      cozy: {
        height: DATA_TABLE_CELL_COZY_HEIGHT
      }
    },
    active: {
      true: {
        cursor: 'pointer',
        position: 'relative'
      }
    },
    selectable: {
      true: { cursor: 'pointer' }
    },
    selected: {
      true: {
        ...mixins.shadow('medium', colorVars.dark600rgb, 0.3),

        backgroundColor: 'light400',
        zIndex: 'above'
      }
    },
    hovered: {
      true: { backgroundColor: 'light200' }
    }
  },
  defaultVariants: {
    size: 'cozy',
    active: false,
    selectable: false,
    selected: false,
    hovered: false
  }
})

const StyledTableCell = styled(Flex, {
  alignSelf: 'center',
  alignItems: 'center',
  flex: '1 1 0',
  fontFamily: 'normal',
  fontSize: 14,
  fontWeight: 'regular',
  letterSpacing: 0,
  lineHeight: 1.2,
  minWidth: 0,
  padding: DATA_TABLE_CELL_PADDING,
  position: 'relative',
  zIndex: 'above',

  variants: {
    size: {
      compact: {
        height: DATA_TABLE_CELL_COMPACT_HEIGHT
      },
      cozy: {
        height: DATA_TABLE_CELL_COZY_HEIGHT
      }
    }
  },
  defaultVariants: {
    size: 'cozy'
  }
})

const StyledCheckboxWrapper = styled('div', {
  cursor: 'pointer',
  padding: DATA_TABLE_CELL_PADDING,
  position: 'relative',
  zIndex: 'above'
})

const StyledDragHandleWrapper = styled('div', {
  position: 'relative',
  zIndex: 'above'
})

const RowCells = ({
  size,
  columns,
  datum,
  isHovered
}: RowCellsProps) => {
  const { actions, selection } = useDataManagerContext()

  const datumWithoutNestedProps = omit(datum, ...nestedProperties)

  return (
    <>
      {columns.filter((col) => !col.hidden).map((column, i: number, filteredCols) => {
        const {
          dataKey,
          template,
          renderer = TextRenderer,
          style,
          renderCellTitle,
          isArray,
          prefix,
          suffix,
          attributeProps,
          displayTypeSettings,
          fieldProps
        } = column

        const isLast = i === filteredCols.length - 1

        const rendererOptions = {
          dataKey,
          template,
          isHovered,
          isArray,
          rowData: datum,
          selection,
          attributeProps,
          style: mixins.textTruncate,
          prefix,
          suffix,
          displayTypeSettings,
          fieldProps
        }

        const getCellTitle = () => {
          const prefixText = prefix ? `${prefix}.` : ''
          const suffixText = suffix ? `.${suffix}` : ''
          const cellData = get(datum, `${prefixText}${dataKey}${suffixText}`)

          if (renderCellTitle) {
            return renderCellTitle(rendererOptions)
          }

          if (typeof cellData === 'boolean') {
            return cellData ? 'true' : 'false'
          }

          return cellData
        }

        const renderCell = (
          <StyledTableCell
            size={size}
            key={dataKey}
            style={style}
            title={renderer === TextRenderer ? getCellTitle() : ''}
          >
            {renderer(rendererOptions)}
          </StyledTableCell>
        )

        if (!isLast) {
          return renderCell
        }

        return (
          <StyledTableCell
            key={dataKey}
            size={size}
            grow={1}
            justifyContent="space-between"
            style={style}
            title={renderer === TextRenderer ? getCellTitle() : ''}
          >
            {renderer(rendererOptions)}
            {!!actions.filter(
              (action) => (action.visibilityFilter ? action.visibilityFilter?.(datum) : true)
            ).length && (
              <RowActions
                record={datumWithoutNestedProps}
                actions={actions}
                isHovered={isHovered}
                offset={DATA_TABLE_CELL_PADDING}
                style={{
                  height: size === 'cozy' ? DATA_TABLE_CELL_COZY_HEIGHT : DATA_TABLE_CELL_COMPACT_HEIGHT
                }}
              />
            )}
          </StyledTableCell>
        )
      })}
    </>
  )
}

function TableRow({
  columns, index, start, dragHandleProps, draggableProps, innerRef, size = 'cozy'
}: TableRowProps) {
  const [ onHoverProps, isRowHovered, setIsRowHovered ] = useHover()

  const {
    data,
    deselectRow,
    onRowSelect,
    selection,
    selectionMode,
    selectRow,
    isDraggable
  } = useDataManagerContext()

  const isRowSelected = selection.indexOf(data[index]?.id) !== -1

  const onRowSelectProps = (selectionMode === 'single' && onRowSelect) ? {
    onClick: () => {
      // If a different row is selected only then we run the below block
      if (!isRowSelected) {
        selection.length && deselectRow(selection[0])
        selectRow(data[index].id)
      }

      onRowSelect(data[index], !isRowSelected)
    }
  } : {}

  function renderCheckBox() {
    const handleChange = (e: React.FormEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()

      if (!isRowSelected) {
        selectRow(data[index].id)
      } else {
        deselectRow(data[index].id)
      }

      onRowSelect?.(data[index], !isRowSelected)
    }

    return (
      <StyledCheckboxWrapper role="presentation" onClick={handleChange}>
        <CheckboxInput
          input={{
            checked: isRowSelected,
            onChange: handleChange
          }}
        />
      </StyledCheckboxWrapper>
    )
  }

  return (
    <StyledTableRow
      onFocusCapture={() => setIsRowHovered(true)}
      onBlurCapture={() => setIsRowHovered(false)}
      selectable={selectionMode === 'single'}
      hovered={isRowHovered && !isRowSelected}
      selected={isRowSelected}
      size={size}
      ref={innerRef}
      {...draggableProps}
      style={{
        ...(start != null ? {
          position: 'absolute',
          top: start,
          left: 0,
          width: '100%'
        } : {
          position: 'relative',
          width: '100%'
        }),

        ...draggableProps?.style
      }}
      {...onRowSelectProps}
      {...onHoverProps}
    >
      {isDraggable && (
        <StyledDragHandleWrapper>
          <DragHandle dragHandleProps={dragHandleProps} />
        </StyledDragHandleWrapper>
      )}

      {selectionMode === 'multiple' && renderCheckBox()}

      <RowCells
        columns={columns}
        datum={data[index]}
        isHovered={isRowHovered}
        size={size}
      />
    </StyledTableRow>
  )
}

export type { TableRowProps }

export default memo(TableRow)
