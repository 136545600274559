import arrayMutators from 'final-form-arrays'
import get from 'lodash/get'
import React, { useContext, useRef } from 'react'
import uniq from 'lodash/uniq'
import { Form, useForm } from 'react-final-form'

import AccountModel from 'models/Account'
import ActionIcon from 'components/icons/ActionIcon'
import Button from 'components/buttons/Button'
import CheckboxInput from 'components/inputs/CheckboxInput'
import Chip from 'components/chip/Chip'
import Divider from 'components/divider/Divider'
import FieldArray, { FieldArrayChildrenProps } from 'components/form/FieldArray'
import Flex from 'components/layout/Flex'
import InternalContext from 'components/contexts/InternalContext'
import Loader from 'components/loaders/Loader'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import Text from 'components/typography/Text'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Account, AccountsListDocument, AccountsListQuery, AccountsListQueryVariables, App, AssignRolesInput, CustomRole, Environment, Group, GroupMembershipsListQueryVariables, GroupsListDocument, GroupsListQuery, RoleMembershipFragmentFragment, RoleMembershipsListDocument, RolesListDocument, RolesListQuery, RolesListQueryVariables, useAssignRolesMutation } from 'generated/schema'
import { Popover, PopoverContainer } from 'components/popover'
import { styled } from 'styles/stitches'
import type { Kind } from 'models/Role'
import type { ViewProps } from 'components/views'

type Params = {
  kind: Kind,
  roleId?: CustomRole['id'],
  accountId?: Account['id'],
  groupId?: Group['id'],
  appId?: App['id'],
  environmentId?: Environment['id'],
  objectId?: string,
  roleMemberships?: Readonly<RoleMembershipFragmentFragment[]>,
  role?: CustomRole,
  account?: Account,
  group?: Group,
  mode?: 'account' | 'group' | 'role'
}

const RepeatedItem = styled(Flex, {
  alignItems: 'center',
  gap: 14,
  grow: 1,
  height: 50,
  paddingX: 16,
  backgroundColor: 'light100',
  borderRadius: 6
})

const AccountSelect = ({
  accountId,
  account
}: { accountId?: string, account?: Account }) => {
  const { getState } = useForm()
  const { values } = getState()
  const getOptionDisabled = (option: Account) => (values.accountIds
    ? values.accountIds.findIndex((f: string) => f === option.id) !== -1
    : false)

  return (
    <SearchSelectField<AccountsListQuery, AccountsListQueryVariables>
      isSearchable
      preload
      isDisabled={!!accountId}
      name="accountIds.0"
      label="Account"
      prependIcon="search"
      placeholder="Start typing to search"
      size="small"
      variant="light"
      labelKey="name"
      metaKey="identifier"
      valueKey="id"
      options={account ? [ account ] : []}
      isOptionDisabled={getOptionDisabled}
      getOptionLabel={
        (option: Account) => AccountModel.getFullNameWithContact(option)
      }
      getOptionMeta={(option: Account) => option.email || option.phone}
      query={AccountsListDocument}
      queryOptions={{
        variables: {
          filter: {
            kind: { in: [ 'MEMBER', 'SERVICE' ] }
          }
        }
      }}
      dataKey="accountsList"
      keys={[ 'name', 'firstName', 'lastName', 'email' ]}
    />
  )
}

const AccountMultiSelect = ({
  values
}: { values: AssignRolesInput }) => {
  const accountFieldsRef = useRef<FieldArrayChildrenProps<Account>>()

  return (
    <FieldArray name="accountIds" fieldsRef={accountFieldsRef}>
      {({ keys, fields }) => {
        const getOptionDisabled = (option: Account) => (fields.value
          ? fields.value.findIndex((f) => f?.id === option.id) !== -1
          : false)
        return (
          <>
            <SearchSelectField<AccountsListQuery, AccountsListQueryVariables>
              isSearchable
              preload
              setValueAsObject
              name="accountId"
              label="Account"
              prependIcon="search"
              placeholder="Start typing to search"
              size="small"
              variant="light"
              labelKey="name"
              metaKey="identifier"
              valueKey="id"
              options={[]}
              isOptionDisabled={getOptionDisabled}
              getOptionLabel={
                (option: Account) => AccountModel.getFullNameWithContact(option)
              }
              getOptionMeta={(option: Account) => option.email || option.phone}
              input={{
                onChange: (value: Account) => fields.push(value)
              }}
              query={AccountsListDocument}
              queryOptions={{
                variables: {
                  filter: {
                    kind: { in: [ 'MEMBER', 'SERVICE' ] }
                  }
                }
              }}
              dataKey="accountsList"
              keys={[ 'name', 'firstName', 'lastName', 'email' ]}
            />
            <Flex direction="column">
              {keys.map((key, index) => {
                const fieldName = FieldArray.getFieldName('accountIds', index)
                const title = AccountModel.getFullNameWithContact(get(values, fieldName))
                const onRemove = () => fields.remove(index)
                return (
                  <>
                    <RepeatedItem key={key} alignItems="center" justifyContent="space-between">
                      <Text fontSize={14} fontWeight="bold">{title}</Text>
                      <ActionIcon
                        name="trash"
                        size={16}
                        onClick={onRemove}
                      />
                    </RepeatedItem>
                    {index !== keys.length - 1 && <Divider spacing={0} variant="ruler" />}
                  </>
                )
              })}
            </Flex>
          </>
        )
      }}
    </FieldArray>
  )
}

const GroupSelect = ({
  groupId,
  group
}: {
  groupId?: string,
  group?: Group
}) => {
  const { getState } = useForm()
  const { values } = getState()
  const getOptionDisabled = (option: Group) => (values.groupIds
    ? values.groupIds.findIndex((f: string) => f === option.id) !== -1
    : false)

  return (
    <SearchSelectField<GroupsListQuery, GroupMembershipsListQueryVariables>
      isSearchable
      preload
      isDisabled={!!groupId}
      name="groupIds.0"
      label="Group"
      prependIcon="search"
      placeholder="Start typing to search"
      size="small"
      variant="light"
      labelKey="name"
      metaKey="identifier"
      valueKey="id"
      options={group ? [ group ] : []}
      isOptionDisabled={getOptionDisabled}
      getOptionLabel={
        (option: Group) => option.name
      }
      query={GroupsListDocument}
      dataKey="groupsList"
      keys={[ 'name' ]}
    />
  )
}

const GroupMuliSelect = ({
  values
}: {
  values: AssignRolesInput
}) => {
  const groupFieldsRef = useRef<FieldArrayChildrenProps<Group>>()
  return (
    <FieldArray name="groupIds" fieldsRef={groupFieldsRef}>
      {({ keys, fields }) => {
        const getOptionDisabled = (option: Group) => (fields.value
          ? fields.value.findIndex((f) => f?.id === option.id) !== -1
          : false)
        return (
          <>
            <SearchSelectField<GroupsListQuery, GroupMembershipsListQueryVariables>
              isSearchable
              preload
              setValueAsObject
              name="groupId"
              label="Group"
              prependIcon="search"
              placeholder="Start typing to search"
              size="small"
              variant="light"
              labelKey="name"
              metaKey="identifier"
              valueKey="id"
              options={[]}
              isOptionDisabled={getOptionDisabled}
              getOptionLabel={
                (option: Group) => option.name
              }
              input={{
                onChange: (value: Group) => fields.push(value)
              }}
              query={GroupsListDocument}
              dataKey="groupsList"
              keys={[ 'name' ]}
            />
            <Flex direction="column">
              {keys.map((key, index) => {
                const fieldName = FieldArray.getFieldName('groupIds', index)
                const title = get(values, fieldName)?.name
                const onRemove = () => fields.remove(index)
                return (
                  <>
                    <RepeatedItem key={key} alignItems="center" justifyContent="space-between">
                      <Text fontSize={14} fontWeight="bold">{title}</Text>
                      <ActionIcon
                        name="trash"
                        size={16}
                        onClick={onRemove}
                      />
                    </RepeatedItem>
                    {index !== keys.length - 1 && <Divider spacing={0} variant="ruler" />}
                  </>
                )
              })}
            </Flex>
          </>
        )
      }}
    </FieldArray>
  )
}

const RoleSelect = ({
  kind,
  roleId,
  appId,
  role
}: {
  kind?: Kind,
  roleId?: string,
  appId?: string,
  role?: CustomRole
 }) => {
  const { change, getState } = useForm()
  const { values } = getState()
  const { environmentsList = [] } = useContext(InternalContext)!

  const selectedEnvironments = values.roles?.[0]?.environmentIds || []
  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      change('roles.0.environmentIds', environmentsList.map((e) => e.id))
    } else {
      change('roles.0.environmentIds', [])
    }
  }
  const isAllChecked = values.roles?.[0]?.environmentIds?.length === environmentsList.length

  return (
    <>
      <SearchSelectField<RolesListQuery, RolesListQueryVariables>
        isSearchable
        preload
        isDisabled={!!roleId}
        name="roles.0.id"
        label="Role"
        prependIcon="search"
        placeholder="Start typing to search"
        size="small"
        variant="light"
        labelKey="name"
        metaKey="identifier"
        valueKey="id"
        options={role ? [ role ] : []}
        getOptionLabel={(option: CustomRole) => option.name}
        query={RolesListDocument}
        queryOptions={{
          variables: {
            filter: {
              ...(appId && { appId: { eq: appId } }),
              ...(roleId && { id: { eq: roleId } })
            }
          }
        }}
        dataKey="rolesList"
        keys={[ 'name' ]}
      />
      {kind === 'APP' && (
        <>
          <CheckboxInput
            input={{
              checked: isAllChecked,
              onChange: onCheckAll
            }}
            label="All"
          />

          {environmentsList?.map((environment) => {
            const checked = get(values, 'roles.0.environmentIds')?.includes(environment.id) || false
            const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                change('roles.0.environmentIds', uniq([ ...selectedEnvironments, environment.id ]))
              } else {
                change('roles.0.environmentIds', selectedEnvironments.filter((e: Environment) => e !== environment.id))
              }
            }

            return (
              <CheckboxInput
                key={environment.id}
                label={environment.name}
                input={{
                  checked,
                  onChange,
                  disabled: isAllChecked
                }}
              />
            )
          })}
        </>
      )}
    </>
  )
}

const RoleEnvironmentSelector: React.FC<{ fieldName: string }> = (
  { fieldName = 'environmentIds' }
) => {
  const { change, getState } = useForm()
  const { values } = getState()
  const { environmentsList = [] } = useContext(InternalContext)!
  const environmentFieldName = `${fieldName}.environmentIds`

  const selectedEnvironments = get(values, environmentFieldName) || null

  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      change(environmentFieldName, null)
    } else {
      change(environmentFieldName, [])
    }
  }

  const isAllChecked = selectedEnvironments === null

  let environmentLabel = 'All Environments'

  if (selectedEnvironments?.length === environmentsList.length) {
    environmentLabel = 'All Environments'
  }

  if (selectedEnvironments?.length > 0) {
    environmentLabel = selectedEnvironments?.map((id: string) => environmentsList.find((e) => e.id === id)?.name).join(', ')
  }

  return (
    <PopoverContainer
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ -38, 8 ]
          }
        }
      ]}
    >
      {({ openPopover, ...toggleProps }) => (
        <Chip
          label={environmentLabel}
          icon="arrow-down"
          iconPlacement="right"
          variant="primary_inverse"
          role="button"
          {...toggleProps}
        />
      )}
      {(popoverProps) => (
        <Popover css={{ padding: 16 }} withArrow {...popoverProps}>
          <Flex direction="column" gap={8}>
            <CheckboxInput
              input={{
                checked: isAllChecked,
                onChange: onCheckAll
              }}
              label="All"
            />

            {environmentsList?.map((environment) => {
              const checked = get(values, environmentFieldName)?.includes(environment.id) || false
              const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  change(environmentFieldName, uniq([ ...selectedEnvironments, environment.id ]))
                } else {
                  change(environmentFieldName, selectedEnvironments.filter(
                    (e: Environment) => e !== environment.id
                  ))
                }
              }

              return (
                <CheckboxInput
                  key={environment.id}
                  label={environment.name}
                  input={{
                    checked,
                    onChange,
                    disabled: isAllChecked
                  }}
                />
              )
            })}
          </Flex>
        </Popover>
      )}
    </PopoverContainer>
  )
}

const RoleMultiSelect = ({
  label = 'Roles',
  fieldName = 'roles'
}: {
  label?: string,
  fieldName?: string
}) => {
  const { getState } = useForm()
  const { values } = getState()
  const roleFieldsRef = useRef<FieldArrayChildrenProps<CustomRole>>()

  return (
    <FieldArray name={fieldName} fieldsRef={roleFieldsRef}>
      {({ keys, fields }) => {
        const getOptionDisabled = (option: CustomRole) => (fields.value
          ? fields.value.findIndex((f) => f?.id === option.id) !== -1
          : false)
        return (
          <>
            <SearchSelectField<RolesListQuery, RolesListQueryVariables>
              isSearchable
              preload
              setValueAsObject
              name="roleId"
              label={label}
              prependIcon="search"
              placeholder="Start typing to search"
              size="small"
              variant="light"
              labelKey="name"
              metaKey="identifier"
              valueKey="id"
              options={[]}
              isOptionDisabled={getOptionDisabled}
              getOptionLabel={(option: CustomRole) => option.name}
              input={{
                onChange: (value: CustomRole) => fields.push(value)
              }}
              query={RolesListDocument}
              dataKey="rolesList"
              keys={[ 'name' ]}
            />
            <Flex direction="column">
              <Loader
                data={keys}
                empty={{
                  title: 'No roles assigned',
                  subtitle: 'Assign a new role'
                }}
              >
                {keys.map((key, index) => {
                  const name = FieldArray.getFieldName(fieldName, index)
                  const field = get(values, name)
                  const isWorkspaceRole = field?.kind === 'WORKSPACE'
                  const title = field?.name
                  const onRemove = () => fields.remove(index)

                  return (
                    <>
                      <RepeatedItem key={key} alignItems="center" justifyContent="space-between">
                        <Text fontSize={14} fontWeight="bold">{title}</Text>
                        <Flex gap={12}>
                          {!isWorkspaceRole && (
                            <RoleEnvironmentSelector fieldName={name} />
                          )}
                          <ActionIcon
                            name="trash"
                            size={16}
                            onClick={onRemove}
                          />
                        </Flex>
                      </RepeatedItem>
                      {index !== keys.length - 1 && <Divider spacing={0} variant="ruler" />}
                    </>
                  )
                })}
              </Loader>
            </Flex>
          </>
        )
      }}
    </FieldArray>
  )
}

function AssignRoleView({
  closeView,
  onRequestClose,
  params: {
    objectId,
    roleId,
    accountId,
    appId,
    groupId,
    environmentId,
    role,
    account,
    group,
    mode,
    kind,
    roleMemberships = []
  },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const [ assignRole ] = useAssignRolesMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      RoleMembershipsListDocument
    ]
  })

  const handleAssignRole = useSubmitHandler(assignRole, {
    successAlert: { message: 'Successfully assigned role.' }
  })

  const handleSubmit = (values: AssignRolesInput) => {
    const { roles, accountIds, groupIds } = values
    const input = {
      ...values,
      roles: roles.map(({ id, environmentIds, objectIds }) => ({
        id,
        environmentIds: environmentIds || [],
        objectIds: objectIds || []
      })),
      accountIds: accountIds?.map((account) => (account?.__typename === 'Account' ? account.id : account)).filter(Boolean),
      groupIds: groupIds?.map((group) => (group?.__typename === 'Group' ? group.id : group)).filter(Boolean)
    }

    return handleAssignRole(input)
  }

  const title = 'Assign Role'

  const initialValues: AssignRolesInput = {
    accountIds: accountId ? [ accountId ] : roleMemberships.map((r) => r.account),
    groupIds: groupId ? [ groupId ] : roleMemberships.map((r) => r.group),
    roles: roleId
      ? [ {
        id: roleId,
        environmentIds: environmentId ? [ environmentId ] : [],
        objectIds: objectId ? [ objectId ] : []
      } ]
      : roleMemberships.map((r) => ({
        ...r.role,
        environmentIds: environmentId ? [ environmentId ] : [],
        objectIds: objectId ? [ objectId ] : []
      }))
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Body>
            <Form
              keepDirtyOnReinitialize
              initialValues={initialValues as any}
              onSubmit={handleSubmit}
              mutators={{
                ...arrayMutators
              }}
              subscription={{
                submitting: true,
                pristine: true,
                values: true
              }}
              render={({ handleSubmit, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <Flex gap={16} direction="column">
                    {kind === 'APP' && (
                      <>
                        {appId && (
                          <RoleSelect
                            appId={appId}
                            kind={kind}
                            roleId={roleId}
                            role={role}
                          />
                        )}
                        {(mode === 'account') && (
                          <AccountSelect accountId={accountId} account={account} />
                        )}
                        {(mode === 'group') && (
                          <GroupSelect groupId={groupId} group={group} />
                        )}
                      </>
                    )}
                    {(kind === 'WORKSPACE' || kind === 'RESOURCE') && (
                      <>
                        {accountId && (
                          <AccountSelect
                            accountId={accountId}
                            account={account}
                          />
                        )}
                        {groupId && (
                          <GroupSelect
                            groupId={groupId}
                            group={group}
                          />
                        )}
                        {roleId && (
                          <RoleSelect
                            kind={kind}
                            roleId={roleId}
                            role={role}
                          />
                        )}
                        {(mode === 'account') && (
                          <AccountMultiSelect values={values} />
                        )}
                        {(mode === 'group') && (
                          <GroupMuliSelect values={values} />
                        )}
                        {mode === 'role' && (
                          <RoleMultiSelect />
                        )}
                      </>
                    )}
                    <Flex gap={24} direction="row-reverse">
                      <Button type="submit" label="Save Changes" disabled={submitting || pristine} />
                    </Flex>
                  </Flex>
                </form>
              )}
            />
          </Body>
        </>
      )}
    </View>
  )
}

export { AccountMultiSelect, GroupMuliSelect, RoleMultiSelect }

export default AssignRoleView
